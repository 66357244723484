<template>
  <div class="network-page">
    <section v-if="!loading">
      <div v-if="articleComment">
        <b-card class="col-12" title="TheHub - Comment Details">
          <b-card-text>
            This page contains details on a comment on an article published on
            TheHub.
          </b-card-text>
        </b-card>

        <div class="row flex-row-reverse">
          <div v-if="showActions" class="col-md-3">
            <b-card title="Actions">
              <b-button
                v-if="articleComment.reply === ''"
                class="full-width mb-1"
                variant="primary"
                @click="showReplyModal"
              >
                Reply
              </b-button>

              <b-button
                v-if="articleComment.status !== 'Approved'"
                class="full-width mb-1"
                variant="success"
                @click="
                  () => {
                    updateArticleComment({
                      status: 'Approved',
                      comment: articleComment.comment,
                    });
                  }
                "
              >
                Approve
              </b-button>
              <b-button
                v-if="articleComment.status !== 'Rejected'"
                class="full-width mb-1"
                variant="warning"
                @click="
                  () => {
                    updateArticleComment({
                      status: 'Rejected',
                      comment: articleComment.comment,
                    });
                  }
                "
              >
                Reject
              </b-button>

              <b-button
                class="full-width mb-1"
                variant="danger"
                @click="
                  () => {
                    updateArticleComment({
                      status: 'Deleted',
                      comment: articleComment.comment,
                    });
                  }
                "
              >
                Delete
              </b-button>
            </b-card>
          </div>
          <div :class="`${showActions && 'col-md-9'}`" class="col-12">
            <b-card title="Article Details">
              <table class="table table-striped">
                <tbody>
                  <tr>
                    <td class="col-3">Title</td>
                    <td>
                      <router-link
                        v-if="$can('Read', 'TheHub.Articles')"
                        :to="`/the-hub/articles/${articleComment.article.id}`"
                        class="font-weight-bold d-block text-nowrap"
                      >
                        {{ articleComment.article.title }}
                      </router-link>
                      <span v-else>{{ articleComment.article.title }}</span>
                    </td>
                  </tr>
                  <tr>
                    <td class="col-3">Excerpt</td>
                    <td>{{ articleComment.article.excerpt }}</td>
                  </tr>
                  <tr>
                    <td class="col-3">Author</td>
                    <td>
                      <router-link
                        v-if="$can('Read', 'User')"
                        :to="`/users/user/${articleComment.article.author.uid}`"
                        class="font-weight-bold d-block text-nowrap"
                      >
                        {{ articleComment.article.author.name }}
                      </router-link>
                      <span v-else>{{
                        articleComment.article.author.name
                      }}</span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </b-card>

            <b-card title="Comment Details">
              <table class="table table-striped mb-2">
                <tbody>
                  <tr>
                    <td class="col-3">Status</td>
                    <td>{{ articleComment.status }}</td>
                  </tr>
                  <tr>
                    <td class="col-3">Date</td>
                    <td>{{ articleComment.date | formatDateTime }}</td>
                  </tr>
                  <tr>
                    <td class="col-3">Device Owner</td>
                    <td>
                      <router-link
                        v-if="$can('Read', 'Owner')"
                        :to="`/owners/owner/${articleComment.owner.uid}`"
                        class="font-weight-bold d-block text-nowrap"
                      >
                        {{ articleComment.owner.identifier }}
                      </router-link>
                      <span v-else>{{ articleComment.owner.identifier }}</span>
                    </td>
                  </tr>
                  <tr v-if="$can('Read', 'TheHub.Likes')">
                    <td class="col-3">Likes</td>
                    <td>{{ articleComment.like_count }}</td>
                  </tr>
                  <tr>
                    <td class="col-3">Comment</td>
                    <td>{{ articleComment.comment }}</td>
                  </tr>
                </tbody>
              </table>

              <b-button
                v-if="showActions"
                class="float-right"
                variant="primary"
                @click="showUpdateCommentModal"
              >
                Update Comment
              </b-button>
            </b-card>

            <b-card v-if="articleComment.reply !== ''" title="Reply Details">
              <table class="table table-striped mb-2">
                <tbody>
                  <tr>
                    <td class="col-3">Date</td>
                    <td>{{ articleComment.replied_on | formatDateTime }}</td>
                  </tr>
                  <tr>
                    <td class="col-3">Replier</td>
                    <td>
                      <router-link
                        v-if="$can('Read', 'User')"
                        :to="`/users/user/${articleComment.replier_uid}`"
                      >
                        {{ articleComment.replier.name }}
                      </router-link>
                      <span v-else>{{ articleComment.replier.name }}</span>
                    </td>
                  </tr>
                  <tr>
                    <td class="col-3">Reply</td>
                    <td>{{ articleComment.reply }}</td>
                  </tr>
                </tbody>
              </table>

              <div class="float-right">
                <b-button
                  v-if="
                    $can('Update', 'TheHubComment') &&
                      articleComment.status !== 'Deleted'
                  "
                  class="mr-1"
                  variant="danger"
                  @click="showDeleteModal"
                >
                  Delete Reply
                </b-button>
                <b-button
                  v-if="showActions"
                  class="float-right"
                  variant="primary"
                  @click="showReplyModal"
                >
                  Update Reply
                </b-button>
              </div>
            </b-card>
          </div>
        </div>

        <b-modal
          id="modal-article-comment-reply"
          hide-footer
          size="lg"
          title="Update Reply"
        >
          <article-comment-update-modal
            :existing="articleComment.reply"
            label="Reply"
            @close="closeModals"
            @update="updateReply"
          />
        </b-modal>

        <b-modal
          id="modal-article-comment-update"
          hide-footer
          size="lg"
          title="Update Comment"
        >
          <article-comment-update-modal
            :existing="articleComment.comment"
            label="Comment"
            @close="closeModals"
            @update="updateComment"
          />
        </b-modal>
      </div>

      <!-- Loading -->
      <section v-else>
        <b-spinner label="Loading TheHub Article Comment" variant="primary" />
      </section>
    </section>
  </div>
</template>

<script>
import TheHubService from "@/services/TheHubService";
import ArticleCommentUpdateModal from "@/views/theHub/sections/ArticleCommentUpdateModal.vue";

export default {
  name: "ViewComment",
  components: { ArticleCommentUpdateModal },
  data() {
    return {
      articleComment: null,
      loading: false,
    };
  },
  computed: {
    showActions() {
      return (
        this.articleComment.status !== "Deleted" &&
        this.$can("Update", "TheHub.Comments")
      );
    },
  },
  mounted() {
    this.getComment();
  },
  methods: {
    async getComment() {
      if (this.loading) {
        return;
      }

      this.loading = true;
      try {
        const { data } = await TheHubService.getArticleComment(
          this.$route.params.id
        );
        this.articleComment = data;
      } catch (err) {
        const res = err.response;
        let errorText = "Could not get comment, please refresh and try again";

        if (res && res.data.error) {
          errorText = res.data.error;
        }

        this.$toast.error(errorText, {
          toastClassName: ["toast-std", "warning-toast"],
        });
        this.$router.push({ name: "comments" });
      } finally {
        this.loading = false;
      }
    },
    async updateArticleComment(dto) {
      if (this.loading) {
        return;
      }

      this.loading = true;
      try {
        const { data } = await TheHubService.updateArticleComment(
          this.$route.params.id,
          dto
        );
        this.articleComment = data;

        if (dto.status === "Deleted") {
          this.$router.push({ name: "comments" });
        }

        this.$toast.success("Comment was successfully updated.", {
          toastClassName: ["toast-std", "success-toast"],
        });
      } catch (err) {
        const res = err.response;
        let errorText =
          "Could not update comment, please refresh and try again";

        if (res && res.data.error) {
          errorText = res.data.error;
        }

        this.$toast.error(errorText, {
          toastClassName: ["toast-std", "warning-toast"],
        });
      } finally {
        this.loading = false;
      }
    },
    async updateReply(reply) {
      if (this.loading) return;

      this.loading = true;
      try {
        let replyDTO = {
          reply: reply,
        };

        const { data } = await TheHubService.updateArticleCommentReply(
          this.$route.params.id,
          replyDTO
        );
        this.articleComment = data;

        this.$toast.success("Reply successfully updated", {
          toastClassName: ["toast-std", "success-toast"],
        });
      } catch (err) {
        const res = err.response;
        let errorText =
          "Could not update comment, please refresh and try again";

        if (res && res.data.error) {
          errorText = res.data.error;
        }

        this.$toast.error(errorText, {
          toastClassName: ["toast-std", "warning-toast"],
        });
      } finally {
        this.loading = false;
      }
    },
    updateComment(comment) {
      this.updateArticleComment({
        status: this.articleComment.status,
        comment: comment,
      });
    },
    async deleteReply() {
      if (this.loading) return;

      this.loading = true;
      try {
        const { data } = await TheHubService.deleteArticleCommentReply(
          this.$route.params.id
        );
        this.articleComment = data;

        this.$toast.success("Reply was successfully deleted.", {
          toastClassName: ["toast-std", "success-toast"],
        });
      } catch (err) {
        const res = err.response;
        let errorText = "Could not delete reply, please refresh and try again";

        if (res && res.data.error) {
          errorText = res.data.error;
        }

        this.$toast.error(errorText, {
          toastClassName: ["toast-std", "warning-toast"],
        });
      } finally {
        this.loading = false;
      }
    },
    showDeleteModal() {
      this.$bvModal
        .msgBoxConfirm(
          "Are you sure you want to delete this reply? This action cannot be undone.",
          {
            title: "Delete Reply",
            size: "sm",
            buttonSize: "sm",
            okVariant: "danger",
            okTitle: "Delete",
            cancelTitle: "Cancel",
            footerClass: "p-2",
            hideHeaderClose: false,
            centered: true,
          }
        )
        .then(value => {
          if (value) {
            this.deleteReply();
          }
        });
    },
    showReplyModal() {
      this.$bvModal.show("modal-article-comment-reply");
    },
    showUpdateCommentModal() {
      this.$bvModal.show("modal-article-comment-update");
    },
    closeModals() {
      this.$bvModal.hide("modal-article-comment-reply");
      this.$bvModal.hide("modal-article-comment-update");
    },
  },
};
</script>

<style lang="scss" scoped></style>

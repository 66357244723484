<template>
  <validation-observer ref="observer" v-slot="{ handleSubmit }">
    <b-form @submit.stop.prevent="handleSubmit(update)">
      <section class="mb-2">
        <label>{{ $props.label }}</label>
        <validation-provider
          v-slot="validationContext"
          :name="$props.label"
          :rules="{ required: true }"
        >
          <b-form-textarea
            v-model="text"
            :state="getValidationState(validationContext)"
            class="mb-1"
            placeholder="Enter your reply here"
          />

          <small class="text-danger">{{ validationContext.errors[0] }}</small>
        </validation-provider>
      </section>

      <section class="d-inline-flex full-width mt-2">
        <div class="ui-spacer" />
        <b-button class="mr-2" @click="$emit('close')">Cancel</b-button>
        <b-button type="submit" variant="primary">Save Changes</b-button>
      </section>
    </b-form>
  </validation-observer>
</template>

<script>
export default {
  name: "ArticleCommentUpdateModal",
  props: {
    existing: {
      type: String,
      default: "",
    },
    label: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      text: "",
    };
  },
  mounted() {
    this.text = this.$props.existing;
  },
  methods: {
    async update() {
      this.$emit("update", this.text);
    },
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
  },
};
</script>

<style lang="scss" scoped></style>
